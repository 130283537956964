import '/assets/styles/pages/faqs.scss';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// // Context
import { useGlobalState } from 'pstv-commerce-tools/utilities/global-state';
import { useModals } from 'pstv-commerce-tools/utilities/modals';

// // Partials
import Loader from '/views/partials/loader'
import Btn from '/views/partials/btn'
import Link from 'pstv-commerce-tools/partials/link';
import Icon from 'pstv-commerce-tools/partials/icon';

// Services
import siteServices from '/services/site'

const getCategoryProducts = () => {
	return siteServices.getFaqs()
}

const Faqs = ({ route, pageProps }) => {
	const { setError } = useGlobalState();
	const { openModal } = useModals();

	const [categories, setCategories] = useState(pageProps?.categories ?? false);

	useEffect(() => {
		if(!categories) {
			getCategoryProducts().then((payload) => {
				setCategories(payload);
			}).catch(() => {
				setError();
			})
		}
	}, [categories])

	return (
		<>
			<header className="account-contenthead">
				<h1 className="contenthead-item">
					{route.head.title}
				</h1>
			</header>
			<section className="account-content account-info">
				<div className="faqs-links">
					{process.config.contact.customerServicesNumber &&
						<Btn className="outline" tag="a" href={`tel:${process.config.contact.customerServicesNumber}`}>
							<span>Müşteri Hizmetleri</span> <strong>{process.config.contact.customerServicesNumberPretty ?? process.config.contact.customerServicesNumber}</strong>
						</Btn>
					}
					<Btn
						onClick={() => {
							openModal('contact');
						}}
						className="outline"
						afterIcon="angle-right">
						İletişim Formu
					</Btn>
				</div>
				<div className="faqs-categories loader-container">
					<Loader loading={!categories} />
					{categories &&
						<ul className="faqs-list">
							{categories.map((category) => (
								<li className="list-faq" key={category.id}>
									<Link
										className="faq-link"
										href="faqDetail"
										params={{ id: category.id, slug: category.slug }}>
										<span>{category.name}</span>
										<Icon name="angle-right" />
									</Link>
								</li>
							))}
						</ul>
					}
				</div>
			</section>
		</>
	)
}

Faqs.propTypes = {
	route: PropTypes.object,
	pageProps: PropTypes.object
}

Faqs.getServerProps = () => {
	return new Promise((resolve) => {
		Promise.all([
			getCategoryProducts()
		]).then(([categories]) => {
			resolve({
				pageProps: {
					categories
				},
			})
		}).catch(() => {
			resolve({
				status: 500,
			})
		})
	})
}

export default Faqs
